/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

//Devexpress Datagrid header customization
.dx-header-row {
    @apply bg-gray-50 dark:bg-gray-700;
}

.tb-full-height,
.tb-full-height > .mat-drawer-inner-container {
    @apply flex h-full flex-col justify-between;
}

// Custom style for DevExpress' delete popup.
// The .dx-visibility-change-handler selector limits the
// style application to only the delete popup, without it
// almost all the dropdowns would be using these rules, so edit carefully
.dx-visibility-change-handler {
    .dx-popup-normal {
        @apply w-128;
        .dx-popup-content {
            @apply p-8;
            .dx-dialog-message {
                @apply text-center font-sans;
            }
        }

        .dx-popup-bottom {
            @apply mt-0 flex items-center justify-end border-t bg-gray-50 py-1 pl-4 pr-8 dark:bg-slate-900;
            .dx-toolbar-button {
                &:nth-child(1) {
                    @extend %dx-delete-popover-buttons;

                    .dx-button {
                        @apply bg-primary text-white;
                    }
                }

                &:nth-child(2) {
                    @extend %dx-delete-popover-buttons;

                    .dx-button {
                        @apply bg-white text-black dark:bg-slate-800/75 dark:text-white;
                    }
                }
            }
        }
    }
}

%dx-delete-popover-buttons {
    .dx-button {
        @apply h-10 min-w-16 rounded-full border-0;
        &.dx-button-default {
            @apply font-sans;
        }

        .dx-button-text {
            @apply m-0;
        }
    }

    .dx-button-content {
        @apply flex flex-row content-baseline font-sans;
        .dx-icon {
            @apply text-white;
        }
    }
}

// Style that makes buttons background rounded and gray
// in DevExpress' DataGrid Headers
.dx-datagrid-header-panel {
    .dx-toolbar-text-auto-hide {
        .dx-button.dx-button-has-icon {
            @apply rounded-full;
            &:hover {
                @apply bg-neutral-100 dark:bg-neutral-100/[.12];
            }
        }
    }
}

//Style for the native html input[type="time"] to make it look like a material input
//note that only webkit browsers will display the picker icon, all the other can only
//specify the desired time by writing it
.tb-mat-time-picker {
    &:hover {
        @apply cursor-text;
    }

    input[type='time'] {
        @apply absolute left-0 right-0 h-full px-4;
        &:hover {
            @apply cursor-text;
        }

        &::-webkit-calendar-picker-indicator {
            @apply block h-4 w-4;
            background: url('data:image/svg+xml,<svg id="clock" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48 padding 12" stroke-width="2" stroke="%2394a3b8" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>') no-repeat;

            &:hover {
                @apply cursor-pointer;
            }
        }
    }
}

//Style for all h elements. All rule have the smallest possible specificity, so they can easily be overridden
h1 {
    @apply text-4xl font-extrabold tracking-tight;
}

h2 {
    @apply text-xl font-medium;
}

h3 {
    @apply text-xl font-medium;
}

h4 {
    @apply text-lg;
}

//Based on coding conventions, base font size of title of dialogs to same rules of h3.
//Set important as the dialog styles have default higher priority than this stylesheet.
.mat-mdc-dialog-container .mat-mdc-dialog-title {
    @apply text-xl font-medium #{!important};
}

//Style for devexpress TabPanel.
.dx-tabpanel-tabs-position-left .dx-tabs {
    border-right: none;
}

%sticky_right {
    @apply sticky right-0;
}

//Styles for all the datagrids to fix the command column to the right and hide the revert button
.dx-datagrid {
    .dx-datagrid-revert-tooltip {
        display: none;
    }

    .dx-header-row {
        .dx-command-edit {
            @extend %sticky_right;
            @apply bg-slate-50 dark:bg-gray-700;
        }
    }

    .dx-data-row {
        .dx-command-edit {
            @extend %sticky_right;
            @apply bg-white dark:bg-dx-dark;
        }
    }
}

//Rule set to customize datagrids appearance, these rules will be applied to all existing datagrids.
//For specific needs define rules inside a custom class and apply it to datagrids that require such customizations.
.dx-datagrid {
    //remove the light-green color that is applied to valued cells of a newly inserted row
    .dx-datagrid-rowsview {
        .dx-adaptive-detail-row,
        .dx-data-row {
            &:not(.dx-edit-row) {
                .dx-cell-modified {
                    @apply bg-transparent;

                    // Add states with nested selectors for hover and selection
                    &.dx-selection {
                        &:hover {
                            @apply bg-transparent;
                        }

                        @apply bg-transparent;
                    }
                }
            }
        }
    }

    //apply the standard focused input color to cells of a newly added row
    .dx-datagrid-focus-overlay {
        &.dx-focused-cell-modified {
            &::after {
                @apply bg-[#769eff];
            }
        }
    }

    //apply a rounded frame to the checkboxes
    .rounded-frame {
        .mdc-checkbox__background {
            border-radius: 50% !important;
        }
    }

    //remove all borders from input fields
    .mat-mdc-form-field.mat-form-field-appearance-fill {
        .mat-mdc-text-field-wrapper {
            @apply border-0;
        }
    }

    //remove unnecessary padding left from lookup
    lookup-control {
        dx-drop-down-box {
            @apply pl-0 #{'!important'};
        }
    }

}

//Rule set to apply to grids that require a color separation between readonly and editable columns
.dx-datagrid-readonly-cols {
    .dx-datagrid-rowsview {
        td {
            &:not([aria-roledescription="Editable"]) {
                &:not(.dx-command-edit) {
                    @apply bg-neutral-50 dark:bg-slate-600/20;
                }
            }
        }
    }
}


// General style for mat dialogs where we would like to not apply padding to.
.dialog-no-padding {
    .mat-mdc-dialog-container {
        .mdc-dialog__container {
            .mat-mdc-dialog-surface {
                padding: 0 !important;
            }
        }
    }
}

//Avoids textarea content to be cut when shrinking the textarea at its smallest dimensio
textarea {
    @apply min-h-6;
}
